import { FormikProps } from 'formik'
import { useRef } from 'react'

import { FormikForm } from '@/presentation/protocols'

export const useFormikForm = <T>({
  initialValues
}: {
  initialValues: T
}): FormikForm<T> => {
  const formRef = useRef<FormikProps<T>>(null)
  const values = formRef.current?.values

  const getFieldValue = (key: keyof T) => formRef.current?.values[key]

  const handleCanSubmit = ({
    dirty,
    isValid
  }: Pick<FormikProps<T>, 'dirty' | 'isValid'>) => {
    return dirty && isValid
  }

  return {
    values,
    formRef,
    initialValues,
    getFieldValue,
    handleCanSubmit
  }
}
